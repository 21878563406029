<template>
  <div class="readme-article">
    <h2 id="网站">5.网站</h2>
    <h3 id="文章管理">5.1文章管理</h3>
    <h4 id="文章列表">5.1.1文章列表</h4>
    <p>
      查看已上传的的文章列表，包含文章标题、封面图、文章分类、状态、添加时间等信息，支持新增、编辑、及删除功能。
    </p>
    <p>
      <img src="@/assets/img/card/5-1.png" />
    </p>
    <h4 id="文章分类">5.1.2文章分类</h4>
    <p>
      查看已上传的的文章分类，包含分类名称、排序、添加时间时间等信息，支持新增、编辑、删除。
    </p>
    <p>
      <img src="@/assets/img/card/5-2.png" />
    </p>
    <h3 id="文件库管理">5.2文件库管理</h3>
    <h4 id="文件分组">5.2.1文件分组</h4>
    <p>
      查看文件库分组，包含分组名称、排序、状态、添加时间等信息，支持新增、编辑、及删除功能。
    </p>
    <p>
      <img src="@/assets/img/card/5-3.png" />
    </p>
    <h4 id="文件列表">5.2.2文件列表</h4>
    <p>
      查看文件列表信息，上传的图片都会存在文件库中，包含分组名称、排序、状态、添加时间等信息，支持移入回收站功能。
    </p>
    <p>
      <img src="@/assets/img/card/5-4.png" />
    </p>
    <h4 id="回收站">5.2.3回收站</h4>
    <p>查看移入回收站的文件，支持还原及彻底删除。</p>
    <p>
      <img src="@/assets/img/card/5-5.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card5-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>